<template>
  <div class="container-productdetails">
    <h1 class="cp-title">
      Lot&nbsp;{{ auctionItemInfoListData.lot }}：{{ headerTitle }}
    </h1>
    <div class="productdetails-header">
      <div class="productdetails-header-left">
        <div class="productdetails-img">
          <a href="javascript:;" @click="topImg()">
            <i class="iconfont icon-xiangshang"></i>
          </a>
          <div class="img-list-more">
            <div class="col-md-3" v-for="(item, index) in imgList" :key="index">
              <div class="thumb-container">
                <div class="thumb">
                  <a href="javascript:;">
                    <img :src="item.url+imgSize(300)" :class="num === index ? 'active' : ''" @click="oneImg(index)" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <a href="javascript:;" @click="bottomImg()">
            <i class="iconfont icon-xiala"></i>
          </a>
        </div>
      </div>
      <!-- <div class="productdetails-header-middle">
        <img v-image-preview :src="index===num?item.url:''" alt="" v-for="(item,index) in imgList" :key="index" />
      </div> -->
      <div class="left">
        <div class="col-md-3">
          <div class="thumb-container">
            <div class="thumb">
              <a href="javascript:;">
                <viewer :images="imgList">
                  <img class="leftImg" :src="item.url+imgSize(2010)" :data-source="item.url" v-show="index === num"
                    v-for="(item, index) in imgList" :key="index" />
                </viewer>
              </a>
            </div>
            <div
              v-if="auctionItemInfoListData.itemAuctionStatus===1&&auctionItemInfoListData.itemAuctionWayId === 7"
              @click="lotRemind()" class="reminderBtn" :class="auctionItemInfoListData.lotRemindStatus?'bg2':'bg1'">
              <i class="iconfont"
                :class="[auctionItemInfoListData.lotRemindStatus?'icon-renwutixing orange':'icon-duanxintixing white']"></i>
              <span
                :class="auctionItemInfoListData.lotRemindStatus?'orange':'white'">{{auctionItemInfoListData.lotRemindStatus?'取消提醒':'开拍提醒'}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="love-toggle">
        <div :class="auctionItemInfoListData.status === false ? 'love' : 'activeA'" @click="tabImgColor" v-if="hide">
        </div>
      </div>
      <div class="productdetails-header-right">
        <div class="productdetails-header-right-top">
          <div class="first-top" v-if="auctionItemInfoListData.itemAuctionWayId === 8">
            <div class="fitst-top-left" v-if="
                auctionItemInfoListData.itemAuctionStatus === 1 &&
                  Date.parse(auctionItemInfoListData.endTime) > Date.parse(dataIf)
              ">
              <span>委托截止时间：</span>
              <span>{{ auctionItemInfoListData.endTime }}</span>
              <span class="first-line1">|</span>
              <span>北京时间</span>
            </div>
            <div class="first-top-time" v-if="
                auctionItemInfoListData.itemAuctionStatus === 1 &&
                Date.parse(auctionItemInfoListData.endTime) > Date.parse(dataIf)
              ">
              <span class="red">{{ time.D }}</span>
              <i>天</i>
              <span class="red">{{ time.h }}</span>
              <i>时</i>
              <span class="red">{{ time.m }}</span>
              <i>分</i>
              <span class="red">{{ time.s }}</span>
              <i>秒</i>
            </div>
            <div class="fitst-top-left" v-if="
                auctionItemInfoListData.itemAuctionStatus === 1 &&
                  Date.parse(auctionItemInfoListData.endTime) < Date.parse(dataIf)
              ">
              <span>委托已结束</span>
            </div>
          </div>
          <div class="first-top first-top-1" v-if="auctionItemInfoListData.itemAuctionWayId === 7">
            <div class="fitst-top-left" v-if="auctionItemInfoListData.itemAuctionStatus === 2">
              <span>此场拍卖会正在直播中</span>
            </div>
            <div class="first-top-time-1" v-if="auctionItemInfoListData.itemAuctionStatus === 2">
              <span>直播中</span>
            </div>
            <div class="first-top-time" style="margin-left:0" v-if="auctionItemInfoListData.itemAuctionStatus === 1">
              <span>直播倒计时：</span>
              <span class="red">{{ time.D }}</span>
              <i>天</i>
              <span class="red">{{ time.h }}</span>
              <i>时</i>
              <span class="red">{{ time.m }}</span>
              <i>分</i>
              <span class="red">{{ time.s }}</span>
              <i>秒</i>
            </div>
            <div class="first-top-time-1" style="background: rgba(205, 161, 86,.5);color:#6e5121"
              v-if="auctionItemInfoListData.itemAuctionStatus === 1">
              <span>待拍</span>
            </div>
            <div class="first-top-time-1" style="background: rgba(222,222,222,.69);color:#999"
              v-if="auctionItemInfoListData.itemAuctionStatus === 4">
              <span>流拍</span>
            </div>
            <div class="first-top-time-1" style="background: rgba(222,222,222,.69);color:#999"
              v-if="auctionItemInfoListData.itemAuctionStatus === 5">
              <span>撤拍</span>
            </div>
            <div class="first-top-time-1" style="background: rgba(205,161,86,.5);color:#6e5121"
              v-if="auctionItemInfoListData.itemAuctionStatus === 3">
              <span>成交</span>
            </div>
          </div>
          <div class="item">
            <span class="label">拍品估价</span>
            <span>:</span>
            <span class="value">{{ auctionItemInfoListDataAssess }}&nbsp;{{
                auctionItemInfoListData.currency
              }}</span>
          </div>
          <div class="item" style="display:flex;">
            <span class="label" v-if="
                (auctionItemInfoListData.nowPrice === '' &&
                  auctionItemInfoListData.itemAuctionStatus !== 3) ||
                  auctionItemInfoListData.itemAuctionStatus === 4 ||
                  auctionItemInfoListData.itemAuctionStatus === 5
              ">起拍价格</span>
            <span class="label" v-else-if="
                auctionItemInfoListData.nowPrice !== '' &&
                  auctionItemInfoListData.itemAuctionStatus !== 3
              ">当前价格</span>
            <span class="label" v-else-if="auctionItemInfoListData.itemAuctionStatus === 3">落槌价</span>

            <!-- <span class="label" v-else>起拍价格</span> -->
            <span>:</span>
            <span class="value" v-if="
                auctionItemInfoListData.nowPrice === '' ||
                  auctionItemInfoListData.itemAuctionStatus === 4 ||
                  (auctionItemInfoListData.itemAuctionStatus === 5 &&
                    auctionItemInfoListData.itemAuctionStatus !== 3)
              ">{{ auctionItemInfoListData.initialPrice }}&nbsp;{{
                auctionItemInfoListData.currency
              }}</span>
            <span class="value" v-else-if="
                auctionItemInfoListData.nowPrice !== '' &&
                  auctionItemInfoListData.itemAuctionStatus !== 3
              ">{{ auctionItemInfoListData.nowPrice }}&nbsp;{{
                auctionItemInfoListData.currency
              }}</span>
            <span class="value" v-if="
                auctionItemInfoListData.itemAuctionStatus === 3 &&
                  isShowuser &&
                  auctionItemInfoListData.nowPrice
              ">请登录</span>
            <span class="value" v-if="
                auctionItemInfoListData.itemAuctionStatus === 3 &&
                  auctionItemInfoListData.nowPrice !== '' &&
                  !isShowuser
              ">{{ auctionItemInfoListData.nowPrice }}&nbsp;{{
                auctionItemInfoListData.currency
              }}</span>
            <div class="accessid" v-if="userShow || auctionItemInfoListData.offerStatus !== '1'">
              <div class="bid-pirce-bottom-right-lxx" v-if="auctionItemInfoListData.offerStatus === '2'">
                <div class="bid-pirce-bottom-go">领先</div>
              </div>
              <div class="bid-pirce-bottom-right-lxx" v-else-if="auctionItemInfoListData.offerStatus === '3'"
                style="border-color:#666;">
                <div class="bid-pirce-bottom-go" style="color:#666;">出局</div>
              </div>
            </div>
          </div>
          <div class="item" style="display:flex;" v-if="auctionItemInfoListData.commission !== null">
            <span class="label">买家佣金</span>
            <span>:</span>
            <!-- <el-table :data="auctionItemInfoListData.commission" border size='small'>
              <el-table-column label="落槌价" width="180">
                <template slot-scope="scope">
                  <span>{{scope.row.amountLow}}</span>~<span style="padding:0;">{{scope.row.amountHigh}}</span>
                </template>
              </el-table-column>
              <el-table-column label="佣金比例" width="180">
                <template slot-scope="scope">
                  <span>{{scope.row.premium}}%</span><span v-if="scope.row.vat !==''">+{{scope.row.vat}}</span>
                </template>
              </el-table-column>
            </el-table> -->
            <table border="1" class="table-w" v-if="
                auctionItemInfoListData.commission !== undefined &&
                  auctionItemInfoListData.commission !== null &&
                  auctionItemInfoListData.commission.length > 1
              ">
              <tr>
                <td aligh="left" class="table-c">落槌价</td>
                <td aligh="right" class="table-c">佣金比例</td>
              </tr>
              <tr v-for="(item, index) in auctionItemInfoListData.commission" :key="index"
                v-show="auctionItemInfoListData.commission !== null">
                <td aligh="left" class="table-c-1">
                  {{ item.amountLow }} ~
                  <span v-if="
                      item.amountHigh === 999999999 ||
                        item.amountHigh === 99999999 ||
                        item.amountHigh === 99999999 ||
                        item.amountHigh === 9999999999
                    ">∞</span>
                  <span v-else>{{ item.amountHigh }}</span>
                </td>
                <td aligh="right" class="table-c-1">
                  <span>{{ item.premium }}%</span>
                  <span v-if="item.vat === true" style="padding:0;">+TAX</span>
                  <a-popover placement="top">
                    <template slot="content">
                      <p>
                        TAX为海外拍卖行根据拍卖行所在国家和地区的税务政策可能会征收的税款，
                      </p>
                      <p>
                        并非所有拍卖行都有此项税费，收费标准不一，具体请以实际成交确认书为准
                      </p>
                    </template>
                    <div class="question-1">
                      <span class="iconfont icon-wenhao-xianxingyuankuang"></span>
                    </div>
                  </a-popover>
                </td>
              </tr>
            </table>
            <span v-else>
              <span>
                {{ commissionOne.premium}}
                <span v-if="commissionOne.premium !== undefined">%</span>
              </span>
              <span v-if="commissionOne.vat === true" style="padding:0;">+VAT</span>
            </span>
          </div>

          <div class="item" v-if="auctionItemInfoListData.serviceFee !== ''">
            <span class="label">平台服务费</span>
            <span>:</span>
            <span class="value">{{ auctionItemInfoListData.serviceFee }}%（按落槌价和买家佣金的总额收取）</span>
          </div>

          <div class="productdetails-pirce">
            <div class="pirce-go">
              <a class="pirce-go-1" href="javascript:;" @click="priceC(1)">-</a>
              <input class="pirce-go-2" v-model="pricrCount" @blur="blurCount" />
              <a class="pirce-go-3" href="javascript:;" @click="priceC(2)">+</a>
            </div>
            <div class="price-count">
              <i>合计：</i>约合人民币<span class="price-count-ny">{{
                contract | Statistic1
              }}</span>元
            </div>
            <div class="w-t-1" v-if="auctionItemInfoListData.itemAuctionWayId === 8 && hide">
              <a href="javascript:" @click="bid()"
                v-if="Date.parse(dataIf) < Date.parse(auctionItemInfoListData.endTime) && hide" class="goPirce">立即出价</a>
              <a href="javascript:" v-else class="goPirce66-6">立即出价</a>
            </div>
            <div class="w-t-1" v-if="auctionItemInfoListData.itemAuctionWayId === 7 && hide">
              <a href="javascript:;" class="goPirce" v-if="
                  auctionItemInfoListData.itemAuctionStatus !== 1 &&
                    auctionItemInfoListData.auctionStatus !== 3
                " style="background: #D41C1C;font-weight: 600;color: #FFFFFF;" @click="LookLive">观看直播</a>
              <div class="look-video" v-if="
                  auctionItemInfoListData.itemAuctionStatus === 1 &&
                    Date.parse(auctionItemInfoListData.startTime) < Date.parse(dataIf) &&
                    auctionItemInfoListData.auctionStatus === 1
                ">
                <a href="javascript:" @click="bid()" class="goPirce-video">立即出价</a>
                <a href="javascript:" @click="LookLive()" class="goPirce-video-2">观看直播</a>
              </div>
              <div class="look-video" v-if="
                  auctionItemInfoListData.auctionStatus === 2 &&
                    Date.parse(auctionItemInfoListData.startTime) > Date.parse(dataIf) &&
                    auctionItemInfoListData.itemAuctionStatus === 1
                ">
                <a href="javascript:" @click="bid()" class="goPirce-video">立即出价</a>
                <a href="javascript:" @click="LookLive()" class="goPirce-video-2">观看直播</a>
              </div>
              <div class="look-video" v-if="
                  auctionItemInfoListData.auctionStatus === 2 &&
                    Date.parse(auctionItemInfoListData.startTime) < Date.parse(dataIf) &&
                    auctionItemInfoListData.itemAuctionStatus === 1
                ">
                <a href="javascript:" @click="bid()" class="goPirce-video">立即出价</a>
                <a href="javascript:" @click="LookLive()" class="goPirce-video-2">观看直播</a>
              </div>
              <a href="javascript:" @click="bid()" v-if="
                  Date.parse(auctionItemInfoListData.startTime) > Date.parse(dataIf) &&
                    auctionItemInfoListData.auctionStatus !== 2
                " class="goPirce">立即出价</a>
            </div>
          </div>
          <div class="bid-pirce">
            <div class="bid-pirce-top">
              <div class="bid-price-top-left">
                <span>出价记录</span>
                <span>(<i class="cda">{{ highest_count }}</i>次竞价)</span>
              </div>
              <div class="bid-price-top-right" v-if="userShow">
                <span v-if="auctionItemInfoListData.offerStatus !== '1'">我的最高出价：</span>
                <span class="cd5656"
                  v-if="auctionItemInfoListData.offerStatus !== '1'">{{ highest_bid }}{{ auctionItemInfoListData.currency }}</span>
                <a style="color:#000" :class="[
                    isChoose === false
                      ? 'iconfont icon-xiangshang'
                      : 'iconfont icon-xiala'
                  ]" @click="isChoose = !isChoose"></a>
              </div>
            </div>
            <transition name="toTop">
              <div class="bid-pirce-bottom" v-show="isChoose">
                <div class="bid-pirce-bottom-one" v-for="(item, index) in CardPartsStatisticsList" :key="index">
                  <div class="bid-pirce-bottom-img">
                    <img :src="item.user_head" alt="" />
                    <span class="text-bid">{{ item.user_name }}</span>
                  </div>
                  <div class="bid-pirce-bottom-middle">
                    <span class="regular" v-if="item.userId === proaccess">我的出价：</span>
                    <span class="regular" style="color:#666" v-else>出&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;价：</span>
                    <span :class="[item.userId === proaccess ? 'cd5656' : 'r6-r']">{{ item.offer
                      }}{{ auctionItemInfoListData.currency }}</span>
                  </div>
                  <div class="bid-pirce-bottom-right" v-if="item.lead === true">
                    <span>{{ item.offer_time }}</span>
                    <div class="bid-pirce-bottom-go">领先</div>
                  </div>
                  <div class="bid-pirce-bottom-right" v-else>
                    <span>{{ item.offer_time }}</span>
                    <div class="go-c">出局</div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <div class="productdetails-header-right-bottom">
          <div class="productdetails-header-right-bottom-text">
            <div class="item">
              <span class="label">拍卖行</span>
              <span>:</span>
              <router-link target="_blank" class="value" style="color:#2c3e50" :to="{
                  path: '/auctionhousedetails',
                  query: {
                    auctionhousedetailsid:
                      auctionItemInfoListData.auctionHouseId
                  }
                }">
                {{ auctionItemInfoListData.auctionHouseTitle }}
              </router-link>
              <!-- <a href="javascript:;" class="value" style="color:#2c3e50"
                @click="auctionHouseTitleDetli()">{{auctionItemInfoListData.auctionHouseTitle}}</a> -->
            </div>
            <div class="item line-d-d">
              <span class="label">拍卖会</span>
              <span>:</span>
              <router-link target="_blank" class="value " style="color:#333" :to="{
                  path: '/auctiondetails',
                  query: { auctiondetailsId: auctionItemInfoListData.auctionId }
                }">
                {{ auctionItemInfoListData.auctionTitle }}
              </router-link>
              <!-- <a href="javascript:;" class="value " style="color:#333"
                @click="goOut()">{{auctionItemInfoListData.auctionTitle}}</a> -->
            </div>

            <div class="item">
              <span class="label">开拍时间</span>
              <span>:</span>
              <span class="value">{{ auctionItemInfoListData.startTime }} | 北京时间</span>
            </div>
            <div class="item">
              <span class="label">拍卖地点</span>
              <span>:</span>
              <span class="value">{{ auctionItemInfoListData.location }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="productdetails-collapse">
      <el-collapse v-model="activeNames">
        <el-collapse-item name="1" title="拍品描述" :disabled="false">
          <div class="productdetails-header-translation">
            <i class="iconfont icon-fanyi"></i>
            <a href="javascript:" @click="translations()">
              翻译
            </a>
          </div>
          <p style="text-align:justify;">
            {{ auctionItemInfoListData.itemOverview }}
          </p>
          <div class="productdetails-collapse-line" v-show="collapseShow">
            <div class="productdetails-collapse-line-top">
              <span></span>
              <span>以下为第三方软件翻译，仅供参考</span>
              <span></span>
            </div>
            <div class="collapse-text">
              {{ chineseTitle }}
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item name="2" :disabled="true" accesskey="true"
          v-if="btnOff && auctionItemInfoListData.phaseReportStatus === 0">
          <button class="btn-quality" @click="quality()">
            获取细节图及品相报告
          </button>
        </el-collapse-item>
        <el-collapse-item name="2" :disabled="true" accesskey="true"
          v-if="btnOff && (auctionItemInfoListData.phaseReportStatus === 1 || auctionItemInfoListData.phaseReportStatus === 3)">
          <button class="btn-quality-1">
            细节图及品相报告获取中…
          </button>
        </el-collapse-item>
        <el-collapse-item name="2" :disabled="true" accesskey="true"
          v-if="btnOff && (auctionItemInfoListData.phaseReportStatus === 2 || auctionItemInfoListData.phaseReportStatus === 4)">
          <router-link target="_blank" class="btn-quality" style="display:inline-block;" :to="{
              path: '/personalcenter',
              query: { application: auctionItemInfoListData.phaseReportId }
            }" @click.native="application()">
            已获取细节图和品相报告
          </router-link>
        </el-collapse-item>
        <el-collapse-item name="3" title="焱十一服务规则">
          <p style="text-align:justify;">
            {{ auctionItemInfoListData.paymentShipping }}
          </p>
        </el-collapse-item>
        <el-collapse-item name="4" title="拍卖规则">
          <p style="text-align:justify;">{{ auctionItemInfoListData.terms }}</p>
        </el-collapse-item>
      </el-collapse>
    </div>

    <div class="full-screen-img" v-show="fullScreenShow" @click="offImg()">
      <img :src="fullScreenImg" alt="" />
    </div>
    <Login ref="login" />
    <el-dialog title="委托出价" :visible.sync="dialogVisible" width="30%">
      <div class="dialog-1">
        <span>委托出价</span>
        <span>{{ getPriceData.commissionPrice
          }}{{ auctionItemInfoListData.currency }}</span>
      </div>
      <div class="dialog-1">
        <span>保证金比例{{ getPriceData.leverage }}</span>
        <span>{{ getPriceData.earnestMoney
          }}{{ auctionItemInfoListData.currency }}</span>
      </div>
      <div class="dialog-1">
        <span>买家佣金</span>
        <span>{{ getPriceData.buyersPremium
          }}{{ auctionItemInfoListData.currency }}</span>
      </div>
      <!-- <div class="dialog-1">
        <span>平台服务费({{auctionItemInfoListData.serviceFee}})</span>
        <span>{{getPriceData.serviceFree}}元</span>
      </div> -->
      <div class="dialog-1 border-1">
        <span>预计出价总额</span>
        <span>{{ getPriceData.estimatedTotalAmount }}元</span>
      </div>
      <div class="dialog-1 border-2" style="margin-bottom:0;">
        <span>已支付保证金</span>
        <span>{{ getPriceData.paidCommissionPrice }}元</span>
      </div>
      <div class="dialog-1 border-2">
        <span>应支付保证金</span>
        <span>{{ getPriceData.payCommissionPrice }}元</span>
      </div>
      <div class="dialog-big">
        <div class="dialog-left">
          <div class="dialog-1-1">
            <i class="iconfont icon-qianbao1" style="color:rgb(205, 152, 86);"></i>
            <span class="paddingRight">余额支付</span>
            <span class="font16">当前账户余额为：</span>
            <span style="color:#cda156;" class="font16">{{
              getPriceData.balance
            }}</span>
            <span class="font16">元</span>
            <a href="javascript:;" class="paddingLeft" @click="goCmonay()">充值</a>
          </div>

          <div class="dialog-1-1">
            <i class="iconfont icon-weixinzhifu" style="color:rgb(0, 179, 45);"></i>
            <span>微信支付</span>
          </div>

          <div class="dialog-1-1">
            <i class="iconfont icon-zhifubao" style="color:rgb(0, 162, 234);"></i>
            <span>支付宝支付</span>
          </div>
        </div>
        <div class="dialog-right">
          <el-radio-group v-model="radio" @change="wxPay($event)">
            <el-radio label="1"></el-radio>
            <el-radio label="2"></el-radio>
            <el-radio label="3"></el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="payCode" v-if="(radio === '2' && resultCode === 0) || radio === '3'">
        <!-- <img :src="url" alt=""> -->
        <ewmCode ref="ewmCode" />
        <span>支付完成后请点击下方支付保证金按钮</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="payLoad()" :disabled="disabledShow">支付保证金</el-button>
        <div class="dialog-2 ">
          <el-checkbox v-model="checked"></el-checkbox>
          <div class="dialog-1-2">
            <p class="dialog-padding">
              我已同意该拍卖的<a href="javascript:;" style="color:#cda156;" @click="termagreement()">《条款与协议》</a>和<a
                href="javascript:;" style="color:#cda156;" @click="termagreement()">《保证金支付条款》</a>
            </p>
          </div>
        </div>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogVisibleT" width="30%">
      <span>请在新打开的页面进行充值。</span>
      <span>充值完成后，根据您的情况点下下面按钮</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rechargeSuccessful">充值成功</el-button>
        <el-button type="primary" @click="dialogVisibleT = false">充值失败</el-button>
      </span>
    </el-dialog>
    <el-dialog title="您的出价不符合规则，请参考出价" :visible.sync="dialogVisibleS" width="40%">
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="priceChoose(priceTwoNo)">{{
          priceTwoNo
        }}</el-button>
        <el-button type="primary" @click="priceChoose(priceStreeNo)">{{
          priceStreeNo
        }}</el-button>
        <el-button @click="priceChoose(priceFour)">{{ priceFour }}</el-button>
      </span>
    </el-dialog>
    <quality-report-pop ref="QualityReportPop" v-on:success="success" />
  </div>
</template>
<script>
  import {
    auctionItemInfo,
    userCollect,
    auctionInfoList,
    getOfferList,
    getPrice,
    weChatRecharge,
    aliRecharge,
    auctionBidding,
    weChatRefund,
    balance,
    alimt,
    lotRemind
  } from '../api/index'
  import Login from './login.vue'
  // import PicZoom from 'vue-piczoom'
  import ewmCode from './ewmCode'
  import QualityReportPop from './Qualityreportpop'
  export default {
    name: 'Productdetails',
    components: {
      // PicZoom,
      Login,
      ewmCode,
      QualityReportPop
    },
    inject: ['reload'],
    data() {
      return {
        collapseShow: false,
        imgList: [],
        activeNames: ['1', '2'],
        num: 0,
        userShow: false,
        dialogVisible: false,
        highest_bid: 0,
        highest_count: 0,
        auctionItemInfoData: {},
        auctionItemInfoListData: {},
        location: '',
        auctionHouseTitle: '',
        productdetailsInfo: {},
        status: null,
        fullScreenImg: '',
        fullScreenShow: false,
        isChoose: null,
        auctionInfoListData: {
          id: null,
          userId: 0,
          auctionWayId: '',
          brandAuthorId: '',
          stylePeriodId: '',
          materialsCraftId: '',
          placeSourceId: '',
          auctionHouseId: '',
          time: '',
          page: 1,
          pageSize: 50,
          title: ''
        },
        activeKey: ['1'],
        url: '',
        CardPartsStatisticsList: [],
        pricrCount: 0,
        Translation: {
          q: '',
          from: 'auto',
          to: 'zh',
          appid: '20210907000937543',
          salt: new Date().getTime(),
          sign: ''
        },
        key: 'mZWupE1nPiE16N8bCtPc',
        chineseTitle: '',
        headerTitle: '',
        truncateData: '',
        time: {
          D: '',
          h: '',
          s: '',
          m: ''
        },
        getOfferListData: {
          itemId: '',
          userId: 0,
          page: 1,
          pageSize: 2000,
          x_user_id: Number(localStorage.getItem('accessId')) || 0,
          authorization: localStorage.getItem('accessToken')
        },
        isEnd: false,
        shouldPay: 1000,
        payfIndex: 0,
        radio: 0,
        radio2: 1,
        checked: true,
        getPriceData: {},
        auctionBiddingData: [],
        priceNum: 0,
        contract: 0,
        outTradeNo: '',
        start: '起拍价格',
        dialogVisibleT: false,
        dialogVisibleS: false,
        priceOneNo: 0, // 第一个推荐金额
        sum: 1,
        priceTwoNo: 0,
        priceStreeNo: 0,
        priceFour: 0,
        proaccess: '',
        heBottom: 130,
        elseBottom: 72,
        dataIf: '',
        resultCode: null,
        disabledShow: false,
        incrementA: 0,
        auctionItemInfoListDataAssess: '',
        commissionOne: {},
        buyersPremium: 0,
        amountHigh: 0,
        visible: false,
        hide: true,
        isShowuser: false,
        auctionBiddingAuction: 0,
        btnOff: true
      }
    },
    filters: {
      // 第二种
      Statistic1(num) {
        num = parseFloat(Number(num).toFixed(3))
        let [integer, decimal] = String.prototype.split.call(num, '.')
        integer = integer.replace(/\d(?=(\d{3})+$)/g, '$&,') // 正则先行断言
        return `${integer}${decimal ? '.' + decimal : ''}`
      }
    },
    created() {
      if (localStorage.getItem('accessId')) {
        this.userShow = true
      }
      if (!localStorage.getItem('accessToken')) {
        this.isShowuser = true
      } else {
        this.isShowuser = false
      }
      if (localStorage.getItem('auctionHouseLoginNum') === '1') {
        this.hide = false
      }
      this.proaccess = Number(localStorage.getItem('accessId'))
      this.productdetailsInfo = this.$route.query.productdetailsId
      sessionStorage.setItem('page1', this.$route.query.page)
      sessionStorage.setItem('msgInfo1', true)
      sessionStorage.setItem('msgInfo15', true)
      sessionStorage.setItem('msgInfo14', true)
      sessionStorage.setItem('msgInfo20', true)
      // sessionStorage.setItem('msgInfo2', true)
      this.auctionItemInfo()

      const that = this
      that.setEndTime()
      this.timeSmall()
    },
    // watch: {
    //   pricrCount: {
    //     handler (newV, oldV) {
    //       // if (Number(newV) < Number(this.auctionItemInfoListData.initialPrice) || Number(newV) < Number(this.auctionItemInfoListData.nowPrice) || isNaN(newV)) {
    //       //   this.$message.error('输入金额禁止小于起拍价或当前价')
    //       //   this.pricrCount = this.auctionItemInfoListData.nowPrice === '' ? Number(this.auctionItemInfoListData.initialPrice) : Number(this.auctionItemInfoListData.nowPrice)
    //       // }

    //     }
    //   }
    // },
    beforeRouteLeave(to, from, next) {
      if (to.path !== '/auctiondetails') {
        sessionStorage.removeItem('msgInfo1')
      }
      next()
    },
    methods: {
      async lotRemind() {
        if (!localStorage.getItem('accessId')) {
          this.$nextTick(() => {
            this.$refs.login.open()
          })
          return
        }
        const res = await lotRemind({
          x_user_id: Number(localStorage.getItem('accessId')) || 0,
          authorization: localStorage.getItem('accessToken'),
          auction_id: this.auctionItemInfoListData.auctionId,
          item_id: this.auctionItemInfoListData.id,
          lot: this.auctionItemInfoListData.lot
        })
        if (res.code === 0) {
          this.auctionItemInfoListData.lotRemindStatus = res.data.flag
          this.$message.success(res.data.flag?'系统将在该拍品直播前10个标的提醒您及时参与竞拍':'取消成功')
        } else {
          this.$message.error(res.msg)
        }
      },
      imgSize(sizeW, sizeh) {
        return `?x-oss-process=image/resize,m_pad,w_${sizeW},h_${sizeh||sizeW}`
      },
      success() {
        this.auctionItemInfo()
      },
      application() {
        localStorage.setItem('informationShow', 13)
      },
      // 不到两天隐藏按钮
      showBtn() {
        var currDate = null
        if (this.auctionItemInfoListData.itemAuctionWayId === 7) {
          currDate = new Date(this.auctionItemInfoListData.startTime)
          var yesterday = new Date(currDate.setDate(currDate.getDate() - 2))
          const time = this.$moment(yesterday).format('YYYY-MM-DD HH:mm')
          const verificationDate = this.$moment().format('YYYY-MM-DD HH:mm')
          if (verificationDate > time) {
            this.btnOff = false
          }
        } else {
          const time1 = this.$moment().format('YYYY-MM-DD HH:mm')
          if (time1 > this.auctionItemInfoListData.endTime) {
            this.btnOff = false
          }
        }
      },
      // 获取品项报告
      quality() {
        if (!localStorage.getItem('accessToken')) {
          this.$refs.login.open()
          return
        }
        if (localStorage.getItem('auctionHouseLoginNum') === '1') {
          this.$message.error('拍卖行账户不可以进行该操作，请切换至买家账户')
          return
        }
        this.$nextTick(() => {
          this.$refs.QualityReportPop.open({
            auctionId: this.auctionItemInfoListData.auctionId,
            itemId: +this.$route.query.productdetailsId
          })
        })
      },
      tabImgColor() {
        if (!localStorage.getItem('accessId')) {
          this.$nextTick(() => {
            this.$refs.login.open()
          })
        } else {
          this.userCollect()
          this.status = !this.status
        }
      },
      // 获取竞价阶梯
      async auctionBidding(id, newMony, num) {
        const res = await auctionBidding({
          auctionId: Number(id),
          currentPrice: newMony || 0,
          type: num
        })
        if (res.code === 0) {
          this.auctionBiddingData = res.data.list
        } else {
          this.$message.error(res.msg)
        }
      },
      Initializing() {
        if (this.auctionItemInfoListData.nowPrice === '') {
          var str = this.auctionItemInfoListData.initialPrice.replace(/,/g, '')
          this.pricrCount = Number(str)
        } else {
          // 输入框中的初始化值
          var str2 = this.auctionItemInfoListData.nowPrice.replace(/,/g, '')
          this.pricrCount = Number(str2)
          // this.OneK()
        }
        this.countCNY()
      },
      // 计算约合人民币函数
      countCNY() {
        // let data5 = []
        // data5 = this.auctionItemInfoListData.commission.filter(item => +this.pricrCount >= +item.amountLow && +this.pricrCount <= +item.amountHigh)
        // this.buyersPremium = Number(this.pricrCount) + (Number(this.pricrCount) * (data5[0].premium) / 100)
        if (this.auctionItemInfoListData.commission === null) {
          this.contract = (
            Number(this.pricrCount) *
            Number(this.auctionItemInfoListData.exchangeRate)
          ).toFixed(0)
          return
        }
        this.buyersPremium = 0
        this.amountHigh = 0
        for (
          let index = 0; index < this.auctionItemInfoListData.commission.length; index++
        ) {
          if (
            this.pricrCount >
            this.auctionItemInfoListData.commission[index].amountLow &&
            this.pricrCount <=
            this.auctionItemInfoListData.commission[index].amountHigh
          ) {
            this.buyersPremium +=
              (this.pricrCount - this.amountHigh) *
              (Number(this.auctionItemInfoListData.commission[index].premium) /
                100)
          }
          if (
            this.pricrCount >
            this.auctionItemInfoListData.commission[index].amountHigh
          ) {
            this.amountHigh +=
              this.auctionItemInfoListData.commission[index].amountHigh -
              this.auctionItemInfoListData.commission[index].amountLow
            this.buyersPremium +=
              (this.auctionItemInfoListData.commission[index].amountHigh -
                this.auctionItemInfoListData.commission[index].amountLow) *
              (Number(this.auctionItemInfoListData.commission[index].premium) /
                100)
          }
        }

        const floor =
          (Number(this.pricrCount) + Number(this.buyersPremium)) *
          Number(this.auctionItemInfoListData.exchangeRate)
        this.contract = floor.toFixed()
        // console.log(this.contract)
        // const contract = Number(this.pricrCount) + (Number(this.pricrCount) * (data5[0].premium) / 100)
        // const floor = (contract * parseFloat(this.auctionItemInfoListData.exchangeRate)).toFixed(0)
        // this.contract = floor
      },
      // 不符合规则
      priceChoose(sumCount) {
        this.pricrCount = sumCount
        this.dialogVisibleS = false
        this.countCNY()
      },
      goCmonay() {
        sessionStorage.setItem('informationShow', 4)
        this.$router.push({
          path: '/personalcenter',
          name: 'Personalcenter',
          query: {
            fige: 1
          }
        })
      },
      // 点击加价
      async priceC(index) {
        if (!localStorage.getItem('accessId')) {
          this.$nextTick(() => {
            this.$refs.login.open()
          })
          return
        }

        // 减
        if (index === 1) {
          const DataInitialPrice = this.auctionItemInfoListData.initialPrice.replace(
            /,/g,
            ''
          )
          const DataNowPrice = this.auctionItemInfoListData.nowPrice.replace(
            /,/g,
            ''
          )
          if (
            this.pricrCount <= Number(DataInitialPrice) ||
            this.pricrCount <= Number(DataNowPrice)
          ) {
            this.$message.error('出价禁止小于起拍价或当前价格')
            if (this.auctionItemInfoListData.nowPrice !== '') {
              this.pricrCount = Number(DataNowPrice)
            } else {
              this.pricrCount = Number(DataInitialPrice)
            }
            return
          }
          // for (let index = 0; index < this.auctionBiddingData.length; index++) {
          //   // if (Number(this.pricrCount) > Number(this.auctionBiddingData[index].increment)) {
          //   //   this.pricrCount = this.auctionBiddingData[index].increment
          //   // }
          //   console.log(this.auctionBiddingData[index].increment)
          // }
          if (+this.pricrCount === +this.auctionBiddingData[0].increment) {
            await this.auctionBidding(
              this.auctionBiddingAuction,
              +this.pricrCount,
              1
            )
          }
          const data = []
          this.auctionBiddingData.forEach(item => {
            if (Number(this.pricrCount) > item.increment) {
              data.push(item.increment)
            }
          })
          const len = data.length
          this.pricrCount = data[len - 1]
          this.countCNY()
        } else if (index === 2) {
          if (
            this.pricrCount < Number(this.auctionItemInfoListData.initialPrice) ||
            this.pricrCount < Number(this.auctionItemInfoListData.nowPrice)
          ) {
            this.$message.error('出价禁止小于起拍价或当前价格')
            if (this.auctionItemInfoListData.nowPrice !== '') {
              this.pricrCount = Number(this.auctionItemInfoListData.nowPrice)
            } else {
              this.pricrCount = Number(this.auctionItemInfoListData.initialPrice)
            }
            return
          }
          const len = this.auctionBiddingData.length
          if (+this.pricrCount === +this.auctionBiddingData[len - 1].increment) {
            await this.auctionBidding(
              this.auctionBiddingAuction,
              +this.pricrCount,
              2
            )
          }
          for (let index = 0; index < this.auctionBiddingData.length; index++) {
            if (+this.pricrCount < +this.auctionBiddingData[index].increment) {
              this.pricrCount = +this.auctionBiddingData[index].increment
              this.countCNY()
              return
            }
          }
        }
      },
      // 充值成功
      rechargeSuccessful() {
        this.wxOkSuccess()
        this.dialogVisibleT = false
        this.start = '当前价格'
        this.auctionItemInfo()
      },
      // 失去焦点计算
      blurCount() {
        this.pricrCount = Number(this.pricrCount)
        if (
          isNaN(this.pricrCount) === true ||
          !/(^[0-9]*[1-9][0-9]*$)/.test(this.pricrCount)
        ) {
          this.pricrCount =
            this.auctionItemInfoListData.nowPrice !== '' ?
            Number(this.auctionItemInfoListData.nowPrice) :
            Number(this.auctionItemInfoListData.initialPrice)
          return
        }
        this.countCNY()
      },
      base64ImgtoFile(dataurl, filename = 'file') {
        const arr = dataurl.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const suffix = mime.split('/')[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], `${filename}.${suffix}`, {
          type: mime
        })
      },
      async wxPay(event) {
        const description =
          '1' +
          ',' +
          this.$route.query.productdetailsId +
          ',' +
          this.contract +
          ',' +
          this.auctionItemInfoListData.auctionId +
          ',' +
          this.auctionItemInfoListData.exchangeRate +
          ',' +
          this.pricrCount +
          ',' +
          this.auctionItemInfoListData.commission
        // 四舍五入转换
        // const amount = this.getPriceData.payCommissionPrice * 100
        // const tempVal = parseFloat(amount).toFixed(3)
        // const realVal = tempVal.substring(0, tempVal.length - 1)
        const payCommissionPrice = this.getPriceData.payCommissionPrice.replace(
          /,/g,
          ''
        )
        if (event === '2') {
          // 微信支付
          const res = await weChatRecharge({
            description: description,
            amount: Number(payCommissionPrice) * 100,
            currency: 'CNY',
            type: 1
          })
          if (res.resultCode === 0) {
            this.resultCode = res.resultCode
            this.url = res.url
            this.$nextTick(() => {
              this.$refs.ewmCode.open(this.url)
            })
            // const img = 'data:image/png;base64,' + res.url
            this.outTradeNo = res.outTradeNo
            // const file = this.base64ImgtoFile(img) // 得到File对象
            // this.url = window.webkitURL.createObjectURL(file) || window.URL.createObjectURL(file) // imgUrl图片网络路径
          } else {
            this.$message.error(res.resultMsg)
            this.disabledShow = true
          }
        } else if (event === '3') {
          const descriptions =
            '1' +
            ',' +
            this.$route.query.productdetailsId +
            ',' +
            this.contract +
            ',' +
            this.auctionItemInfoListData.auctionId +
            ',' +
            this.auctionItemInfoListData.exchangeRate +
            ',' +
            this.pricrCount

          // 支付宝支付
          const payCommissionPrice = this.getPriceData.payCommissionPrice.replace(
            /,/g,
            ''
          )
          const res = await aliRecharge({
            subject: descriptions,
            type: 1,
            totalAmount: Number(payCommissionPrice) * 100,
            // returnUrl: `https://www.test.easyebid.com/#/productdetails?productdetailsId=${this.$route.query.productdetailsId}`
            returnUrl: `https:///www.easyebid.com/#/productdetails?productdetailsId=${this.$route.query.productdetailsId}`
          })
          if (res.resultCode === 0) {
            // window.location.href = res.url
            // window.open(res.url, '_blank')
            // this.dialogVisibleT = true
            this.outTradeNo = res.outTradeNo
            this.$nextTick(() => {
              this.$refs.ewmCode.open(res.url)
            })
          } else {
            this.$message.error(res.resultMsg)
          }
        } else if (event === '1') {
          this.disabledShow = false
        }
      },
      // 支付
      async payLoad() {
        if (this.checked === false) {
          this.$message.error('请您同意协议')
          return
        }
        if (this.radio === 0) {
          this.$message.error('请选择支付方式')
        } else if (this.radio === '1') {
          // 余额
          const balancea =
            '1' +
            ',' +
            this.auctionItemInfoListData.id +
            ',' +
            this.contract +
            ',' +
            this.auctionItemInfoListData.auctionId +
            ',' +
            this.auctionItemInfoListData.exchangeRate +
            ',' +
            this.pricrCount
          if (
            Number(this.getPriceData.balance) <
            Number(this.getPriceData.payCommissionPrice)
          ) {
            this.$message.error('余额不足,请充值')
            return
          }
          this.disabledShow = true
          var str = this.getPriceData.payCommissionPrice.replace(/,/g, '')
          const res = await balance({
            description: balancea,
            totalAmount: Number(str),
            bond: 0
          })
          if (res.resultCode === 0) {
            this.$message.success('支付成功')
            this.dialogVisible = false
            this.reload()
          } else {
            this.$message.error(res.resultMsg)
          }
          const time = setInterval(() => {
            this.disabledShow = false
            clearInterval(time)
          }, 2000)
        } else if (this.radio === '2' || this.radio === '3') {
          // 充值完查询充值状态
          this.wxOkSuccess()
        }
        // else if (this.radio === '3') {
        // // 支付宝支付
        // const payCommissionPrice = this.getPriceData.payCommissionPrice.replace(/,/g, '')
        // const res = await aliRecharge({
        //   subject: description,
        //   type: 1,
        //   totalAmount: Number(payCommissionPrice) * 100,
        //   // returnUrl: `https://www.test.easyebid.com/#/productdetails?productdetailsId=${this.$route.query.productdetailsId}`
        //   returnUrl: `https:///www.easyebid.com/#/productdetails?productdetailsId=${this.$route.query.productdetailsId}`
        // })
        // if (res.resultCode === 0) {
        //   // window.location.href = res.url
        //   // window.open(res.url, '_blank')
        //   // this.dialogVisibleT = true
        //   this.outTradeNo = res.outTradeNo
        //   this.$nextTick(() => {
        //     this.$refs.ewmCode.open(res.url)
        //   })
        // } else {
        //   this.$message.error(res.resultMsg)
        // }
        // }
      },
      async wxOkSuccess() {
        const res = await weChatRefund({
          outTradeNo: this.outTradeNo
        })
        if (res.resultCode === 0) {
          this.$message.success('支付成功')
          this.dialogVisible = false
          this.start = '当前价格'
          this.auctionItemInfo()
        } else if (res.resultCode === 102) {
          this.$message.error(res.resultMsg)
        }
      },
      // 出价后加一口
      OneK() {
        let actire = []
        actire = this.auctionBiddingData.filter(
          item => +this.pricrCount >= +item.min && +this.pricrCount <= +item.max
        )
        if (this.pricrCount === Number(actire[0].max)) {
          this.pricrCount += Number(actire[1].increment)
        } else {
          this.pricrCount += Number(actire[0].increment)
        }
      },
      // 获取出价列表
      async getOfferList(id) {
        const res = await getOfferList(this.getOfferListData)
        if (res.code === 0) {
          this.CardPartsStatisticsList = res.data.offerList
          this.highest_bid = res.data.highest_bid
          this.highest_count = res.data.count
          if (this.CardPartsStatisticsList === null) {
            this.isChoose = false
          } else {
            this.isChoose = true
          }
        } else {
          this.$message.error(res.msg)
        }
      },
      // 用户点击收藏
      async userCollect() {
        const res = await userCollect({
          userid: Number(localStorage.getItem('accessId')),
          itemid: Number(this.productdetailsInfo),
          authorization: localStorage.getItem('accessToken'),
          x_user_id: Number(localStorage.getItem('accessId'))
        })
        if (res.code === 0) {
          this.auctionItemInfo()

          if (this.auctionItemInfoListData.status === true) {
            this.$message.error('取消成功')
          } else {
            this.$message.success('收藏成功')
          }
        } else {
          this.$message.error(res.msg)
        }
      },
      setEndTime() {
        const that = this
        const interval = setInterval(function timestampToTime() {
          var date =
            new Date(
              that.auctionItemInfoListData.itemAuctionWayId === 8 ?
              that.auctionItemInfoListData.endTime.replace(/-/g, "/") :
              that.auctionItemInfoListData.startTime.replace(/-/g, "/")
            ) - new Date() // 计算剩余的毫秒数
          if (date < 0) {
            that.isEnd = true
            that.time = {
              D: '00',
              h: '00',
              s: '00',
              m: '00'
            }
            clearInterval(interval)
          } else {
            that.time.D = parseInt(date / 1000 / 60 / 60 / 24, 10) // 计算剩余的天
            if (that.time.D < 10) {
              that.time.D = '0' + that.time.D
            }
            that.time.h = parseInt((date / 1000 / 60 / 60) % 24, 10) // 计算剩余的小时
            if (that.time.h < 10) {
              that.time.h = '0' + that.time.h
            }
            that.time.m = parseInt((date / 1000 / 60) % 60, 10) // 计算剩余的分钟
            if (that.time.m < 10) {
              that.time.m = '0' + that.time.m
            }
            that.time.s = parseInt((date / 1000) % 60, 10) // 计算剩余的秒数
            if (that.time.s < 10) {
              that.time.s = '0' + that.time.s
            }
            return that.time.D + that.time.h + that.time.m + that.time.s
          }
        }, 1000)
      },

      // 详情数据初始化
      async auctionItemInfo() {
        const res = await auctionItemInfo({
          id: Number(this.productdetailsInfo),
          userId: Number(localStorage.getItem('accessId')) || 0,
          x_user_id: Number(localStorage.getItem('accessId')) || 0,
          authorization: localStorage.getItem('accessToken')
        })
        if (res.code === 0) {
          this.auctionItemInfoListData = res.data
          this.headerTitle = this.auctionItemInfoListData.title
          // this.commissionOne = this.auctionItemInfoListData.commission[0]
          this.imgList = res.data.images
          this.getOfferListData.itemId = res.data.id
          this.getOfferListData.userId = Number(localStorage.getItem('accessId'))
          if (this.auctionItemInfoListData.commission !== null) {
            this.commissionOne = this.auctionItemInfoListData.commission[0]
          }
          const ifDataa = this.auctionItemInfoListData.assess.split('-')
          if (ifDataa[1] === ' 0') {
            this.auctionItemInfoListDataAssess = ifDataa[0]
          } else {
            this.auctionItemInfoListDataAssess = this.auctionItemInfoListData.assess
          }
          this.getOfferList(res.data.auctionHouseId)
          this.auctionBiddingAuction = res.data.auctionId
          this.auctionBidding(this.auctionBiddingAuction)
          this.Initializing()

          sessionStorage.setItem(
            'initialPrice',
            Number(this.auctionItemInfoListData.initialPrice)
          )
          this.showBtn()
        } else {
          this.$message.error(res.msg)
        }
      },
      // 浏览本场拍卖会
      async goOut() {
        this.auctionInfoListData.id = this.auctionItemInfoListData.auctionId
        this.$router.push({
          path: '/auctiondetails',
          name: 'Auctiondetails',
          query: {
            auctiondetailsId: this.auctionItemInfoListData.auctionId
          }
        })
      },
      // 点击翻译
      async translations() {
        this.collapseShow = !this.collapseShow

        if (this.collapseShow === true) {
          this.alimt()
          if (this.auctionItemInfoListData.title === '') {
            return
          }
          const res = await alimt({
            sourceLanguage: 'auto',
            sourceText: this.auctionItemInfoListData.title,
            targetLanguage: 'zh'
          })
          if (res.resultCode === 200) {
            this.headerTitle = res.text
          }
        } else {
          this.headerTitle = this.auctionItemInfoListData.title
        }
      },
      async alimt() {
        const res = await alimt({
          sourceLanguage: 'auto',
          sourceText: this.auctionItemInfoListData.itemOverview,
          targetLanguage: 'zh'
        })
        if (res.resultCode === 200) {
          this.chineseTitle = res.text
        } else {
          this.$message.error(res.resultMsg)
        }
      },
      // 向上切换
      topImg() {
        if (this.num <= this.imgList.length - 1) {
          if (this.num > 0) {
            this.num--
          } else {
            this.num = this.imgList.length - 1
          }
        }
      },
      // 向下切换
      bottomImg() {
        if (this.num < this.imgList.length - 1) {
          this.num++
        } else {
          this.num = 0
        }
      },
      // 立即出价
      async bid() {
        this.radio = 0
        if (!localStorage.getItem('accessId')) {
          this.$nextTick(() => {
            this.$refs.login.open()
          })
          return
        }

        if (this.auctionItemInfoListData.itemAuctionWayId === 8) {
          if (
            this.time.D === '00' &&
            this.time.h === '00' &&
            this.time.m === '00' &&
            this.time.s === '00'
          ) {
            this.$message.error('委托时间已截止')
            return
          }
        }

        if (this.auctionItemInfoListData.userStatus === false) {
          this.$message.error('请先实名认证，正在跳转中...')
          localStorage.setItem('informationShow', 9)
          var timeGo = setTimeout(() => {
            this.$router.push({
              path: '/personalcenter',
              name: 'Personalcenter'
            })
            clearInterval(timeGo)
          }, 2000)
          return
        }

        if (
          isNaN(this.pricrCount) === true ||
          !/(^[0-9]*[1-9][0-9]*$)/.test(this.pricrCount)
        ) {
          this.pricrCount =
            this.auctionItemInfoListData.nowPrice !== '' ?
            Number(this.auctionItemInfoListData.nowPrice) :
            Number(this.auctionItemInfoListData.initialPrice)
          this.$message.error('请输入正确金额')
          return
        }
        const DataInitialPrice = this.auctionItemInfoListData.initialPrice.replace(
          /,/g,
          ''
        )
        const DataNowPrice = this.auctionItemInfoListData.nowPrice.replace(
          /,/g,
          ''
        )

        if (
          this.pricrCount < Number(DataInitialPrice) ||
          this.pricrCount <= Number(DataNowPrice)
        ) {
          this.$message.error('出价禁止小于起拍价或当前价格')
          this.dialogVisible = false
          if (this.auctionItemInfoListData.nowPrice !== '') {
            this.pricrCount = Number(DataNowPrice)
          } else {
            this.pricrCount = Number(DataInitialPrice)
          }
          return
        }
        if (
          this.auctionItemInfoListData.initialPrice !== '' &&
          Number(DataInitialPrice) === this.pricrCount
        ) {
          this.immediateOffer()
          return
        }
        const indexFind = this.auctionBiddingData.find(
          item => Number(item.increment) === Number(this.pricrCount)
        )
        if (indexFind === undefined) {
          this.$message.error('出价不符合规则')
          for (let index = 0; index < this.auctionBiddingData.length; index++) {
            if (this.pricrCount < this.auctionBiddingData[index].increment) {
              this.pricrCount = this.auctionBiddingData[index].increment
              return
            }
          }
        }
        this.immediateOffer()
      },
      // 立即出价弹窗封装
      async immediateOffer() {
        this.dialogVisible = true

        const res = await getPrice({
          itemId: Number(this.$route.query.productdetailsId),
          userId: Number(localStorage.getItem('accessId')) || 0,
          commissionPrice: this.pricrCount.toString(),
          x_user_id: Number(localStorage.getItem('accessId')) || 0,
          authorization: localStorage.getItem('accessToken')
        })
        if (res.code === 0) {
          this.getPriceData = res.data
          this.dialogVisible = true
        } else {
          this.$message.error(res.msg)
          this.dialogVisible = false
          this.auctionItemInfo()
        }
      },
      // 跳转拍卖行
      auctionHouseTitleDetli() {
        this.$router.push({
          path: '/auctionhousedetails',
          name: 'Auctionhousedetails',
          query: {
            auctionhousedetailsid: this.auctionItemInfoListData.auctionHouseId
          }
        })
      },
      // 切换图片
      oneImg(index) {
        this.num = index
      },
      // 时间转换
      timeSmall() {
        var date = new Date() // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-'
        var M =
          (date.getMonth() + 1 < 10 ?
            '0' + (date.getMonth() + 1) :
            date.getMonth() + 1) + '-'
        var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() + ' '
        var h =
          (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
        var m =
          (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
          ':'
        var s =
          date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
        this.dataIf = Y + M + D + ' ' + h + m + s // 时分秒可以根据自己的需求加上
      },
      // 条款与协议
      termagreement() {
        this.$router.push({
          path: '/termagreement',
          name: 'Termagreement'
        })
      },
      LookLive() {
        // if (!localStorage.getItem('accessId')) {
        //   this.$nextTick(() => {
        //     this.$refs.login.open()
        //   })
        //   return
        // }
        this.$router.push({
          path: '/buyerlive',
          name: 'Buyerlive',
          query: {
            liveId: this.auctionItemInfoListData.id,
            title: this.auctionItemInfoListData.auctionTitle,
            auctionId: this.auctionItemInfoListData.auctionId,
            auctionHouseTitle: this.auctionItemInfoListData.auctionHouseTitle
          }
        })
      }
    },
    mounted() {}
  }
</script>
<style lang="less" scoped>
  .container-productdetails {
    margin: 0 90px;
    box-sizing: border-box;

    .cp-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .seamless-warp {
      height: 229px;
      overflow: hidden;
    }

    /deep/ .el-dialog__footer {
      text-align: center;
    }

    /deep/ .el-collapse-item__header {
      font-size: 24px;
      font-weight: 400;
    }

    /deep/ .el-button {
      width: 200px;
      font-weight: 600;
      font-size: 18px;
      color: #6e5121;
      opacity: 0.69;
      border-radius: 8px;
      background: #cda156;
      height: 44px;
      border: none;
    }

    /deep/ .el-dialog__wrapper {
      z-index: 100 !important;
    }

    /deep/ .el-radio-group {
      display: flex;
      flex-direction: column;
      text-align: left;
      padding-top: 11px;
    }

    /deep/ .el-radio {
      margin-right: 0;
      padding-bottom: 32px;
    }

    h1 {
      font-size: 20px;
      font-weight: 600;
      color: #333;
      text-align: left;
      padding-top: 20px;
      text-align: left;
      padding-left: 10px;
    }

    /deep/ .ant-collapse-header {
      text-align: left;
      padding: 30px 5px;
      font-size: 24px;
    }

    /deep/ .mouse-cover-canvas {
      left: 923px !important;
      top: 323px !important;
      width: 400px !important;
      height: 400px !important;
    }

    /deep/ .el-radio__input.is-checked .el-radio__inner {
      background: #cda156;
      border-color: #cda156;
    }

    /deep/ .el-radio__inner {
      border-radius: 0;
      border-color: #b3b0b0;
    }

    /deep/ .el-checkbox__inner {
      border-color: #b3b0b0;
    }

    /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #cda156;
    }

    // /deep/ .el-checkbox__inner {
    // }
    /deep/ .el-radio__inner:hover {
      border-color: #cda156;
    }

    /deep/ .ant-collapse {
      background-color: #fff;
      border: none;
      position: relative;
    }

    /deep/ .el-radio__label {
      font-size: 12px;
      color: transparent;
      padding-left: 0;
    }

    /deep/ .el-radio__input.is-checked+.el-radio__label {
      color: transparent;
    }

    /deep/ .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
      left: 1690px;
      font-size: 24px;
    }

    /deep/ .el-table {
      max-width: 362px;
    }

    /deep/ .el-table--border {
      border-right: none !important;
    }

    // /deep/ .ant-collapse-item {
    //   height: 93px;
    // }
    /* 放大的图片，通过定位将左上角定位到(0,0) */
    .rightImg {
      display: inline-block;
      width: 800px;
      height: 800px;
      position: absolute;
      top: 0;
      left: 0;
    }

    /* 右边的区域图片放大空间 */
    .right {
      // margin-left: 412px;
      width: 330px;
      height: 330px;
      border: 1px solid #cda165;
      position: absolute;
      left: 50%;
      overflow: hidden;
    }

    /* 原图的容器 */
    .left {
      border: 1px solid #e6e8e8;
      margin: 0 70px;
      box-sizing: content-box;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      .col-md-3 {
        width: 670px;

        margin: 0;
        border: none;
        height: none;
        display: flex;
        // justify-content: center;
        // align-items: center;
        height: 670px;

        .thumb-container {
          width: 100%;
          // height: 405px;
          position: relative;
          padding-bottom: 100%;
          margin: 0;
          border: none;

          .bg1 {
            background: rgba(0, 0, 0, 0.4);

          }

          .bg2 {
            background: rgba(255, 255, 255, 0.42);
          }

          .reminderBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 36px;
            padding: 0 14px;
            border-radius: 18px;
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;

            >i {
              font-size: 20px;
            }

            >span {
              font-size: 18px;
              font-weight: 400;
              color: #FFFFFF;
              margin-left: 6px;
            }

            .white {
              color: #ffffff;

            }

            .orange {
              color: #CDA156;
            }
          }

          &:hover {
            box-shadow: none;
          }

          .thumb {
            position: absolute;
            width: 100%;
            height: 100%;
            text-align: center;
            margin: 0;
            border-top: none;
            border-bottom: none;
            border-left: none;

            // top: 32%;
            &:hover {
              box-shadow: none;
            }

            a {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              max-width: 100%;
              max-height: 100%;

              img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                max-width: 100%;
                max-height: 100%;
              }
            }
          }
        }
      }
    }

    .productdetails-collapse {
      p {
        text-align: left;
      }

      /deep/.el-collapse-item {
        position: relative;
      }

      .productdetails-header-translation {
        display: flex;
        position: absolute;
        top: 10px;
        left: 110px;
        width: 70px;
        height: 25px;
        border: 1px solid #cda156;
        border-radius: 4px;
        align-items: center;
        justify-content: center;

        i {
          color: #cda156;
        }

        a {
          color: #cda156;
          padding-left: 5px;
          font-size: 14px;
          // margin-left: 40px;
        }
      }
    }

    .full-screen-img {
      width: 100vw;
      height: calc(100vh);
      background: rgba(121, 121, 121, 0.6);
      position: absolute;
      // top: 0;
      // left: 0;
      bottom: 0;
      right: 0;

      img {
        width: 30%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    /deep/ .el-dialog__title {
      font-size: 24px;
      font-weight: 600;
      color: #333;
    }

    .dialog-2 {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #333;
      font-weight: 400;
      padding: 20px 0 0 0;

      .dialog-1-2 {
        display: flex;
        // align-items: center;
        // padding-top: 6px;
        justify-content: center;

        input[type='checkbox'] {
          position: relative;
          cursor: pointer;
          width: 12px;
          height: 12px;
          font-size: 12px;
        }

        input[type='checkbox']:checked::after {
          position: absolute;
          top: 0;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 12px;
          height: 12px;
          content: '✓';
          color: #fff;
          font-size: 12px;
          font-weight: 700;
          background-color: #cda156;
          border-radius: 2px;
        }

        .dialog-padding {
          padding-left: 10px;
          font-size: 16px;
        }

        p {
          margin-bottom: 0;
        }
      }
    }

    .dialog-big {
      display: flex;
      justify-content: space-between;

      .dialog-left {
        font-size: 20px;
        color: #333;
        font-weight: 400;

        .dialog-1-1 {
          padding-bottom: 20px;
          text-align: left;

          .paddingRight {
            padding-right: 10px;
          }

          .font16 {
            font-size: 16px;
            color: #999;
          }

          .paddingLeft {
            text-decoration: underline;
            color: #cda156;
            padding-left: 10px;
          }
        }

        i {
          font-size: 20px;
          padding-right: 5px;
        }
      }
    }

    .payCode {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 256px;
      }
    }

    .dialog-1 {
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      color: #333;
      font-weight: 400;
      padding-bottom: 10px;

      .dialog-1-1 {
        .paddingRight {
          padding-right: 10px;
        }

        .font16 {
          font-size: 16px;
          color: #999;
        }

        .paddingLeft {
          text-decoration: underline;
          color: #cda156;
          padding-left: 10px;
        }
      }

      i {
        font-size: 20px;
        padding-right: 5px;
      }
    }

    .border-1 {
      border-bottom: 1px solid #dedede;
    }

    .border-2 {
      border-bottom: 1px solid #dedede;
      font-weight: 600;
      padding-top: 10px;
      margin-bottom: 20px;
    }

    .productdetails-collapse-line {
      display: flex;
      flex-direction: column;

      .collapse-text {
        text-align: left;
      }

      .productdetails-collapse-line-top {
        display: flex;
        align-items: center;
        margin: 40px 0;

        span:nth-child(1) {
          width: 715px;
          height: 1px;
          background: #d8d8d8;
        }

        span:nth-child(2) {
          font-size: 12px;
          color: #999;
          font-weight: 400;
          padding: 0 40px;
        }

        span:nth-child(3) {
          width: 715px;
          height: 1px;
          background: #d8d8d8;
        }
      }
    }

    .btn-quality {
      width: 270px;
      height: 40px;
      background: #cda156;
      border-radius: 8px;
      line-height: 40px;
      color: #fff;
      font-size: 18px;
      border: none;
    }

    .btn-quality-1 {
      width: 270px;
      height: 40px;
      background: #999;
      border-radius: 8px;
      line-height: 40px;
      color: #fff;
      font-size: 18px;
      border: none;
    }

    .active {
      border: 4px solid #cda156;
    }

    .productdetails-header {
      display: flex;
      margin-top: 23px;
      padding-bottom: 60px;
      border-bottom: 1px solid #dedede;

      .productdetails-header-left {
        a {
          color: #000;

          i {
            font-size: 36px;
          }
        }

        .productdetails-img {
          display: flex;
          flex-direction: column;

          .img-list-more::-webkit-scrollbar {
            /*隐藏滚轮*/
            display: none;
          }

          .img-list-more {
            height: 580px;
            overflow-y: scroll;
          }

          .col-md-3 {
            width: 100px;
            margin: 26px 0;
            border: none;
            height: none;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            height: 100px;

            .thumb-container {
              width: 100%;
              // height: 405px;
              position: relative;
              padding-bottom: 100%;
              margin: 0;
              border: none;

              &:hover {
                box-shadow: none;
              }

              .thumb {
                position: absolute;
                width: 100%;
                height: 100%;
                text-align: center;
                margin: 0;
                border-top: none;
                border-bottom: none;
                border-left: none;

                // top: 32%;
                &:hover {
                  box-shadow: none;
                }

                a {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                  max-width: 100%;
                  max-height: 100%;

                  img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    max-width: 100%;
                    max-height: 100%;
                  }
                }
              }
            }
          }

          // img {
          //   width: 100px;
          //   height: 100px;
          //   margin: 26px 0;
          // }
        }
      }

      .productdetails-header-middle {
        width: 670px;
        height: 670px;
        margin: 0 70px;
        // padding: 0 70px;
        overflow: hidden;

        .follow {
          background-color: yellow;
          width: 200px;
          height: 200px;
          opacity: 0.3;
          cursor: move;
        }

        img {
          width: 670px;
        }
      }

      .love:hover {
        background: url('../assets/images/17.png') no-repeat;
        background-size: 40px;
      }

      .activeA {
        background: url('../assets/images/17.png') no-repeat !important;
        background-size: 40px !important;
        width: 40px;
        height: 40px;
        margin: 0;
        background-size: 40px;
        // border: transparent;
        border-radius: 50%;
      }

      .love {
        width: 40px;
        height: 40px;
        background: url('../assets/images/9.png') no-repeat;
        background-size: 40px;
        border: transparent;
        border-radius: 50%;
      }

      .productdetails-header-right {
        margin-left: 30px;

        .productdetails-header-right-top {
          display: flex;
          flex-direction: column;
          // align-items: self-end;
          text-align: left;

          .first-top-1 {
            justify-content: space-between;
            padding-right: 0 !important;
          }

          .first-top {
            // width: 740px;
            height: 50px;
            background: #f9f9f9;
            margin-bottom: 20px;
            display: flex;
            align-items: center;

            padding: 0 20px;

            .fitst-top-left {
              font-size: 20px;
              font-weight: 400;
              color: #333;

              .first-line1 {
                padding: 0 10px;
              }
            }

            .first-top-time {
              margin-left: 60px;
              font-size: 20px;

              i {
                padding: 0 5px;
                font-style: normal;
              }

              .red {
                color: #cd5656;
              }
            }

            .first-top-time-1 {
              width: 160px;
              height: 50px;
              background: rgba(206, 1, 1, 0.6);
              line-height: 50px;
              text-align: center;

              font-weight: 400;

              span {
                opacity: 1;
                color: #6e2121;
                font-size: 18px;
              }
            }
          }

          .item {
            // height: 32px;
            line-height: 32px;
            margin-bottom: 8px;
            font-size: 20px;
            margin-left: 20px;

            span:nth-child(2) {
              padding: 0 20px;
            }

            .table-w {
              width: 350px;
              border: 1px solid #f3f3f3;

              .table-c {
                font-size: 16px;
                color: #333333;
                font-weight: 400;
              }

              .table-c-1 {
                font-size: 16px;
                color: #666;
                font-weight: 400;

                .question-1 {
                  display: inline-block;

                  span {
                    padding-left: 8px;
                  }
                }
              }
            }

            .bid-pirce-bottom-right-lxx {
              display: inline-block;
              margin-left: 10px;
              width: 50px;
              height: 30px;
              border: 1px solid #cda156;
              line-height: 30px;
              text-align: center;
              color: #cda156;
              margin-left: 10px;
              border-radius: 4px;
            }

            .label {
              display: inline-block;
              width: 120px;
              height: 100%;
              text-align: justify;
              text-align-last: justify;
              font-size: 20px !important;
            }

            .color-a {
              color: #2c3e50 !important;
            }
          }

          p {
            margin-bottom: 20px;
            color: #333;

            span:nth-child(2) {
              font-weight: 600;
            }
          }

          .productdetails-pirce {
            width: 740px;
            height: 50px;
            margin: 30px 0 30px 20px;
            border-radius: 8px;
            display: flex;
            align-items: center;

            input {
              outline: none;
              border: none;
            }

            .pirce-go {
              border: 1px solid #bfc3c4;

              border-radius: 8px;
              display: flex;
              font-size: 20px;

              .pirce-go-1 {
                width: 50px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                color: #666;
                border-right: 1px solid #bfc3c4;
              }

              .pirce-go-2 {
                width: 80px;
                height: 50px;
                text-align: center;
                line-height: 48px;
              }

              .pirce-go-3 {
                width: 50px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                color: #666;
                border-left: 1px solid #bfc3c4;
              }
            }

            .price-count {
              width: 300px;
              padding-left: 20px;
              font-size: 18px;

              i {
                font-weight: 600;
                font-style: normal;
              }

              .price-count-ny {
                color: #cda156;
              }
            }

            .w-t-1 {
              height: 100%;
            }

            .look-video {
              display: flex;
              width: 265px;
              justify-content: space-between;

              .goPirce-video {
                width: 150px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #cda156;
                color: #fff;
                font-weight: 600;
                font-size: 18px;
                border-radius: 8px;
              }

              .goPirce-video-2 {
                width: 100px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                // background: #cda156;

                border: 1px solid #cd5656;

                color: #cd5656;
                font-weight: 400;
                font-size: 18px;
                border-radius: 8px;
              }
            }

            .goPirce {
              width: 240px;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;

              height: 100%;
              background: #cda156;
              color: #fff;
              font-weight: 600;
              font-size: 18px;
              border-radius: 7px;
            }

            .goPirce66-6 {
              width: 240px;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;

              height: 100%;
              background: #999;
              color: #fff;
              font-weight: 600;
              font-size: 18px;
              border-radius: 7px;
            }
          }
        }

        .bid-pirce {
          width: 100%;

          // height: 189px;
          .bid-pirce-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 49px;
            border: 1px solid #dedede;
            padding: 0 10px;

            .bid-price-top-left {
              font-size: 18px;

              .cda {
                color: #cda156;
              }

              span {
                i {
                  font-style: normal;
                }
              }
            }

            .bid-price-top-right {
              font-size: 18px;

              .cd5656 {
                color: #cd5656;
                padding-right: 5px;
              }

              .r6-r {
                color: #666;
              }
            }
          }

          .toTop-enter {
            opacity: 0;
          }

          .toTop-enter-active {
            transition: opacity 0.5s;
          }

          .toTop-leave-to {
            opacity: 0;
          }

          .toTop-leave-active {
            transition: opacity 0.5s;
          }

          .bid-pirce-bottom {
            width: 100%;
            height: 130px;
            border-left: 1px solid #dedede;
            border-right: 1px solid #dedede;
            border-bottom: 1px solid #dedede;
            padding: 20px;
            overflow-y: scroll;

            .bid-pirce-bottom-one {
              display: flex;
              align-items: center;
              padding-bottom: 20px;

              .bid-pirce-bottom-img {
                width: 189px;
                display: flex;
                align-items: center;

                .text-bid {
                  padding-left: 5px;
                  font-size: 18px;
                  color: #333;
                }

                img {
                  width: 40px;
                  border-radius: 50%;
                }
              }

              .bid-pirce-bottom-middle {
                width: 220px;
                font-size: 18px;

                .regular666 {
                  color: #666;
                }

                .regular {
                  color: #333;
                }

                .cd5656 {
                  color: #cd5656;
                }
              }

              .bid-pirce-bottom-right {
                width: 270px;
                display: flex;
                align-items: center;
                font-size: 16px;
                padding-left: 46px;
                justify-content: space-between;

                .go-c {
                  border: 1px solid #666666;
                  color: #666;
                  width: 50px;
                  height: 30px;
                  line-height: 30px;
                  text-align: center;
                  margin-left: 10px;
                  border-radius: 4px;
                }

                .bid-pirce-bottom-go {
                  width: 50px;
                  height: 30px;
                  border: 1px solid #cda156;
                  line-height: 30px;
                  text-align: center;
                  color: #cda156;
                  margin-left: 10px;
                  border-radius: 4px;
                }
              }
            }
          }
        }

        .productdetails-header-right-bottom {
          margin-top: 30px;
          padding-left: 20px;

          .productdetails-header-right-bottom-text {
            font-size: 18px;
            font-weight: 400;
            color: #333;
            text-align: left;

            .item {
              height: 32px;
              line-height: 32px;
              margin-bottom: 8px;

              span {
                font-size: 20px;
              }

              span:nth-child(2) {
                padding: 0 20px;
              }

              a {
                &:hover {
                  text-decoration: underline;
                }
              }
            }

            .label {
              display: inline-block;
              width: 80px;
              height: 100%;
              text-align: justify;
              text-align-last: justify;
            }

            &:after {
              display: inline-block;
              width: 100%;
              content: '';
              height: 0;
            }

            .value {
              padding-right: 6px;
            }

            .line-d-d {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 600px;
            }
          }

          .productdetails-header-right-bottom-block {
            display: flex;

            a:nth-child(1) {
              width: 290px;
              height: 50px;
              border-radius: 8px;
              border: 1px solid #cda156;
              line-height: 50px;
              text-align: center;
              color: #cda156;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
</style>
