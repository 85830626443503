<template>
  <div>
    <el-dialog :visible.sync="dialogTableVisible" width="38.05%">
      <div class="concretely">具体要求</div>
      <div class="btn-data">
        <button class="btn" :class="{ active: item.marking }" :key="index" v-for="(item, index) in btnData"
          @click="onBtnChange(item)">
          {{ item.name }}
        </button>
      </div>
      <div class="concretely">其他要求</div>
      <div class="btn-data">
        <textarea placeholder="如有其它需求,请在此输入…" class="textarea" v-model="textareaData"></textarea>
      </div>
      <div class="btn-submit">
        <button @click="submissionApplication()" class="submission">
          提交申请
        </button>
        <button @click="dialogTableVisible = false" class="sub-close">
          关闭
        </button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="success" width="18.8%">
      <img class="image-success" src="../assets/images/tongguo.png" alt="" />
      <div>提交成功,我们将在2个工作日内回复您~</div>
    </el-dialog>
    <el-dialog :visible.sync="realName" width="18.8%">
      <img class="image-fail" src="../assets/images/failIcon.png" />
      <div class="title">请您先实名认证后，再获取品相报告</div>
      <span slot="footer" class="dialog-footer">
        <div class="btn-footer">
          <span @click="realName = false">确定</span>
          <span @click="goRealName()">实名认证</span>
        </div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import {
    addPhaseReport
  } from '../api/index'
  export default {
    data() {
      return {
        dialogTableVisible: false,
        success: false,
        realName: false,
        textareaData: '',
        propsData: {},
        btnData: [{
            id: 1,
            name: '全部',
            marking: false
          },
          {
            id: 2,
            name: '侧面',
            marking: false
          },
          {
            id: 3,
            name: '背面',
            marking: false
          },

          {
            id: 4,
            name: '底部',
            marking: false
          },


          {
            id: 5,
            name: '内部',
            marking: false
          },

          {
            id: 6,
            name: '局部细节',
            marking: false
          },

          {
            id: 7,
            name: '款识',
            marking: false
          },

          {
            id: 8,
            name: '破损处',
            marking: false
          },

          {
            id: 9,
            name: '瑕疵',
            marking: false
          },
          {
            id: 10,
            name: '口沿',
            marking: false
          }
        ],
        specificRequirement: []
      }
    },
    methods: {
      goRealName() {
        this.$router.push({
          path: '/personalcenter',
          name: 'Personalcenter',
          query: {
            informationShow: 9 // 显示实名认证
          }
        })
      },
      open(data) {
        this.dialogTableVisible = true
        this.textareaData = ''
        this.propsData = data
        this.btnData.forEach(item => {
          if (item.marking === true) {
            item.marking = false
          }
        })
        this.specificRequirement = []
      },
      onBtnChange(data) {
        let find = {}
        find = this.btnData.find(item => item.id === data.id)
        find.marking = !find.marking
        if (data.id === 1 && data.marking === true) {
          for (let index = 0; index < this.btnData.length; index++) {
            this.btnData[index].marking = true
          }
        } else if (data.id === 1 && data.marking === false) {
          for (let index = 0; index < this.btnData.length; index++) {
            if (this.btnData[index].id !== 1) {
              this.btnData[index].marking = false
            }
          }
        }
      },
      // 提交申请
      async submissionApplication() {
        this.btnData.forEach(item => {
          if (item.marking === true) {
            this.specificRequirement.push(item.name)
          }
        })
        if (this.specificRequirement.length === 0 && this.textareaData === '') {
          return this.$message.error('请选择具体要求或其他要求')
        }
        const res = await addPhaseReport({
          userId: Number(localStorage.getItem('accessId')) || 0,
          x_user_id: Number(localStorage.getItem('accessId')) || 0,
          authorization: localStorage.getItem('accessToken'),
          id: 0,
          auctionId: this.propsData.auctionId,
          itemId: this.propsData.itemId,
          specificRequirement: this.specificRequirement.toString(),
          otherRequirements: this.textareaData,
          itemDetailDrawing: '',
          phaseReport: ''
        })
        if (res.code === 0) {
          this.dialogTableVisible = false
          this.success = true
          this.$emit('success', '')
        } else {
          if (res.msg == '请前往个人中心进行实名认证，再来获取品相报告') {
            this.dialogTableVisible = false
            this.realName = true
          } else {
            this.$message.error(res.msg)
          }
        }
      }
    }
  }
</script>
<style lang="less">
  /deep/ .el-checkbox-button .el-checkbox-button__inner {
    display: inline-block !important;
    width: 120px;
    height: 40px;
  }

  .concretely {
    text-align: left;
    font-weight: 600;
    color: #333;
    font-size: 20px;
    padding-left: 10px;
    border-left: 2px solid #cda156;
    border-radius: 2px;
    margin-bottom: 10px;
  }

  .concretely:last-child {
    margin-top: 20px;
  }

  .btn-data {
    text-align: left;

    .btn {
      width: 120px;
      height: 40px;
      line-height: 40px;
      border-radius: 8px;
      border: 1px solid #bfc3c4;
      font-size: 18px;
      font-weight: 400;
      color: #333;
      background: none;
      margin: 0 15px 10px 0;
      display: inline-block;

      &:hover {
        color: #cda156;
        border: 1px solid #cda156;
        background: rgba(205, 161, 86, 0.1);
      }
    }

    .active {
      color: #cda156;
      border: 1px solid #cda156;
      background: rgba(205, 161, 86, 0.1);
    }

    .textarea {
      resize: none;
      width: 660px;
      height: 140px;
      background: #fff;
      border: 1px solid #bfc3c4;
      font-size: 18px;
      padding-left: 10px;
      padding-top: 10px;
      color: #999;
      outline: none;
    }
  }

  .btn-submit {
    margin-top: 20px;

    button {
      height: 44px;
      border-radius: 8px;
      font-size: 18px;
      font-weight: 400;
      line-height: 44px;
    }

    .submission {
      width: 280px;
      background: rgba(221, 192, 139);
      color: #6e5121;
      border: none;
      margin-right: 20px;

      // opacity: 0.7;
    }

    .sub-close {
      width: 160px;
      border: 1px solid #cda156;
      color: #cda156;
      background: none;
    }
  }

  .image-success {
    width: 184px;
    height: 144px;
    padding-bottom: 20px;
  }

  .image-fail {
    width: 90px;
    height: 90px;
    margin-bottom: 20px;
  }

  .title {
    color: #333333;
    font-size: 18px
  }

  .btn-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    >span {
      cursor: pointer;
      font-size: 18px;
      color: #6E5121;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-child {
        width: 140px;
        height: 40px;
        background: #F5EDDE;
      }

      &:nth-child(2) {
        width: 140px;
        height: 40px;
        background: #DCBE8A;
      }
    }
  }
</style>
